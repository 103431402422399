<template>
    <div class="cadastros-basicos-page">
        <Breadcrumb titulo="CADASTROS BÁSICOS" :items="item" />

        <div class="cadastros-basicos-container-cartoes">
            <div class="cadastros-basicos-page-cartoes" v-for="cartao in cartoesDisponiveis" v-if="cartoes[cartao]" :key="cartoes[cartao].id">
                <cartao-dinamico :titulo="cartoes[cartao].titulo"
                                 :subtitulo="cartoes[cartao].subtitulo"
                                 :classe="cartoes[cartao].classe"
                                 :cor="cartoes[cartao].cor"
                                 :iconBootstrap="cartoes[cartao].icon_bootstrap"
                                 @click="acessar(cartoes[cartao].link)" />
            </div>
        </div>
      
    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Breadcrumb from '../components/breadcrumb/Breadcrumb.vue'
    import CartaoDinamico from '../components/cartao/CartaoDinamico.vue'
    import axios from 'axios'
    export default {
        name: 'CadastrosBasicosPage',

        components: {
            Breadcrumb,
            CartaoDinamico
        },

        data: function () {
            return {
                busca: '',
                empresaDados: null,
                canais: [],
                item: [
                    {
                        id: '2',
                        classe: 'fa fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    }
                ],
                cartoes: {
					"chatbot-layout": {
						id: '1',
						titulo: 'CHATBOT LAYOUT',
						subtitulo: 'Configurações ChatBot',
						classe: 'fa fa-robot',
						link: '/chat-configuracao',
						cor: '#d1d325',
						icon_bootstrap: 'vazio'
					},
					"tabulacao": {
						id: '2',
						titulo: 'TABULAÇÃO',
						subtitulo: 'Tabulações do Atendimento',
						classe: 'fa fa-clipboard',
						link: '/tabulacao',
						cor: '#39d325',
						icon_bootstrap: 'vazio'
					},
					"setor": {
						id: '3',
						titulo: 'SETOR',
						subtitulo: 'Setor de Usuários',
						classe: 'fa fa-users-cog',
						link: '/setores',
						cor: '#d35e25',
						icon_bootstrap: 'vazio'
					},
					"usuarios": {
						id: '4',
						titulo: 'USUÁRIOS',
						subtitulo: 'Manutenção de Usuário',
						classe: 'fa fa-user',
						link: '/usuarios',
						cor: '#95f9a6',
						icon_bootstrap: 'vazio'
					},
					"canal": {
						id: '5',
						titulo: 'CANAL',
						subtitulo: 'Canais',
						classe: 'fa fa-globe',
						link: '/canais',
						cor: '#d325a4',
						icon_bootstrap: 'vazio'
					},
					"clientes": {
						id: '7',
						titulo: 'CLIENTES',
						subtitulo: 'Configurações de clientes',
						classe: 'fas fa-users',
						link: '/clientes',
						cor: '#3925d3',
						icon_bootstrap: 'vazio'
					},
					"integracoes": {
						id: '9',
						titulo: 'INTEGRAÇÕES',
						subtitulo: 'Integrações e parâmetros',
						classe: 'fa fa-user-plus',
						link: '/configuracoes',
						cor: '#a212f5',
						icon_bootstrap: 'vazio'
					},
					"configuracao-pausas": {
						id: '11',
						titulo: 'CONFIGURAÇÃO PAUSAS',
						subtitulo: 'Configurar as pausas do usuário',
						classe: 'fa fa-stopwatch',
						link: '/configuracoes-pausas',
						cor: '#3925d3',
						icon_bootstrap: 'vazio'
					},
					"formularios": {
						id: '12',
						titulo: 'Formularios',
						subtitulo: 'Manutenção de formularios',
						classe: 'fa fa-scroll',
						link: '/gerenciarformularios',
						cor: '#1d1368',
						icon_bootstrap: 'vazio'
					},
					"templates-mensagem": {
						id: "13",
						titulo: "TEMPLATES DE MENSAGEM",
						subtitulo: "Templates de Mensagem do Atendimento",
						classe: "fas fa-hashtag",
						link: "/templates-mensagem",
						cor: "#74e4ff",
						icon_bootstrap: "vazio"
					},
					"produtos": {
						id: "14",
						titulo: "Produtos",
						subtitulo: "Cadastros de produtos",
						classe: "fas fa-box",
						link: "/produtos-produtos",
						cor: "#eee",
						icon_bootstrap: "vazio"
					},
					"gpt": {
						id: "15",
						titulo: "GPT",
						subtitulo: "Configurações chat GPT",
						classe: 'fa fa-robot',
						link: "/configuracaogpt",
						cor: "#eee",
						icon_bootstrap: "vazio"
					},
					"ramais-telefonicos": {
						id: 16,
						titulo: 'RAMAIS TELEFÔNICOS',
						subtitulo: 'Gerenciamento de ramais telefônicos',
						classe: 'fas fa-fax',
						link: '/ramais',
						cor: '#FD7E14',
						icon_bootstrap: 'vazio'
					}
                },
            }
        },
        created() {
            this.buscaEmpresaDados();
			axios.get("api/Atendimento/GetEnumAtendimentoOrigem").then(response => {
				this.canais = (response.data ?? []).map(item => item.Value);
			});
        },
        computed: {
            ...mapGetters({
                dadosUsuarioAutenticado: 'authentication/authDadosUsuario',
                getUsuarioPorId: 'usuarios/getUsuarioById',
                loading: 'loadingGlobal',
                ModulosDisponiveis: 'modulos_integracoes/modulosItegracoes',

			}),
            usuario: function () {
                if (this.dadosUsuarioAutenticado) {
                    return this.getUsuarioPorId(this.dadosUsuarioAutenticado.usuarioId);
                }
                return {};
            },
            cartoesDisponiveis() {
				let cartoesDisponiveis = [];

				if (this.empresaDados.includes("12")) cartoesDisponiveis.push("formularios");
				if (this.empresaDados.includes("13")) cartoesDisponiveis.push("templates-mensagem");
				if (this.empresaDados.includes("14")) cartoesDisponiveis.push("gpt");
				if (this.canais.includes(7)) cartoesDisponiveis.push("ramais-telefonicos");

				return ["chatbot-layout", "tabulacao", "setor", "usuarios", "canal", "clientes", "integracoes", "configuracao-pausas", "produtos", ...cartoesDisponiveis];
            }
        },

        methods: {
            ...mapActions({
                buscaModulos: 'modulos_integracoes/buscaModulos'
            }),
            buscaEmpresaDados() {
                const mod = this.$store.getters['authentication/authDadosUsuario'];
                this.empresaDados = mod.ModulosEmpresa;
            },
            filtro: function ($event) {
                this.busca = $event.toUpperCase();
            },
            acessar(link) {
                this.$router.push(link);
            },
            removeByAttr : function (arr, attr, value) {
                var i = arr.length;
                while (i--) {
                    if (arr[i]
                        && arr[i].hasOwnProperty(attr)
                        && (arguments.length > 2 && arr[i][attr] === value)) {

                        arr.splice(i, 1);

                    }
                }
                return arr;
            }
        }
    }
</script>

<style scoped>
    .cadastros-basicos-page {
        padding-bottom: 120px;
    }

    .grid-container {
        display: grid;
        grid-template-areas: 'main main main';
    }

    .cadastros-basicos-page-cartoes {
        width: 33%;
        padding: 10px;
    }

    .cadastros-basicos-container-cartoes {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 28px 12px;
    }
</style>